
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { initializeApp } from "firebase/app";
import GlobalConstants from './app/common/global-constants';


const firebaseConfig = {
  apiKey: "AIzaSyBMxcojbflt1i4h52805VLWx38Z121sDrA",
  authDomain: "real-talk-esque.firebaseapp.com",
  databaseURL: "https://real-talk-esque.firebaseio.com",
  projectId: "real-talk-esque",
  storageBucket: "real-talk-esque.appspot.com",
  messagingSenderId: "388987909366",
  appId: "1:388987909366:web:459da2828df9b2467828d7",
  measurementId: "G-CHBLRQTH67"
};

// Initialize Firebase
GlobalConstants.firebaseApp = initializeApp(firebaseConfig);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
