import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.currentUserValue) {
      const userAuthorities = this.authService.currentUserValue.authorities;

        
      if (
        !route.data.role ||
        userAuthorities?.filter(
          (userRole) => route.data.role.indexOf(userRole) > -1
        ).length
      ) {
        return true;
      }

      this.router.navigate(["/authentication/signin"]);
      return false;
    }

    this.router.navigate(['/authentication/signin']);
    return false;
  }
}
